import axios from 'axios'
import {
  decoratedRequestWithApiKey,
  decoratedRequestWithCommonHeaders,
  decoratedRequestWithToken
} from './serviceDecorators.js'

export const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_RETAIL_SE_BASE_URL}`
})

serviceInstance.interceptors.request.use((request) => {
  let newRequest = decoratedRequestWithApiKey(request)
  newRequest = decoratedRequestWithCommonHeaders(newRequest)
  newRequest = decoratedRequestWithToken(newRequest)
  return newRequest
})

export default {
  getCapability: async () => {
    return serviceInstance.post(`/capability`, {})
  },
  getPdf: async (printId) => {
    return serviceInstance.get(`/print/${printId}/pdf`)
  }
}
