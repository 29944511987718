import {
  decoratedRequestWithApiKey,
  decoratedRequestWithCommonHeaders,
  decoratedRequestWithToken
} from '@/shared/services/serviceDecorators.js'
import axios from 'axios'

const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_RETAIL_SE_BASE_URL}/deliveryagreements`
})

serviceInstance.interceptors.request.use((request) => {
  let newRequest = decoratedRequestWithApiKey(request)
  newRequest = decoratedRequestWithCommonHeaders(newRequest)
  newRequest = decoratedRequestWithToken(newRequest)
  return newRequest
})

export default {
  getAll: async () => {
    return serviceInstance.get()
  },
  create: async (payload) => {
    return serviceInstance.post('', payload)
  },
  update: async (payload) => {
    return serviceInstance.patch(`/${payload.id}`, payload)
  },
  delete: async (id) => {
    return serviceInstance.delete(`/${id}`)
  }
}
