<template>
  <div
    :class="[
      showSideMenu && desktopCollapsed ? 'page-container--collapsed' : '',
      showSideMenu ? 'page-container' : ''
    ]"
  >
    <SideMenu
      v-if="showSideMenu"
      :active-menu="currentRouteName"
      @toggle-mobile-sidebar="mobileCollapsed = $event"
      @toggle-desktop-sidebar="desktopCollapsed = $event"
    />
    <div
      class="main-component"
      :class="[mobileCollapsed ? '' : 'page-component--collapsed']"
      :style="{ overflow: shouldLoadFromIFrame ? 'hidden' : 'auto' }"
    >
      <CurrentServicepointBanner
        v-if="isActingAsSp"
        :servicepoint-name="servicepoint?.name"
        :display-name="fullName"
        @switch-to-main="showConfirmSwitchBackModal = true"
        @switch-ssid="showSsidSwitchModal = true"
      />
      <SsidSwitchModal
        :show="showSsidSwitchModal"
        :env="environment"
        @did-close="showSsidSwitchModal = false"
        @did-switch="handleDidSwitchSp"
      />
      <ConfirmModal
        class="switch-back-modal"
        :title="`${$t('APP.SHAPESHIFTER_TITLE')} ${fullName}`"
        :message="`${$t('APP.SHAPESHIFTER_MESSAGE1')} ${
          servicepoint?.name
        }. ${$t('APP.SHAPESHIFTER_MESSAGE2')}`"
        :show="showConfirmSwitchBackModal"
        @cancel="showConfirmSwitchBackModal = false"
        @confirm="handleSwitchBackToMainAccount"
      />
      <AppLoader v-if="shouldLoadFromIFrame && iframeOpacity === 0" />
      <div v-if="shouldLoadFromIFrame" class="iframe-container">
        <iframe
          v-if="toolUrl"
          ref="toolIframe"
          title="Single access point iframe"
          :src="toolUrl"
          :class="{ 'shape-shifted': isActingAsSp }"
          :style="{ opacity: iframeOpacity }"
          @load="onIframeLoaded"
        />
      </div>
      <div v-else>
        <Hero v-if="isInDashboard" :content="heroContent" />
        <div :class="showSideMenu ? 'page-component' : ''">
          <router-view v-slot="{ Component }">
            <CurrentLoginControl
              v-if="canShapeshift && !isActingAsSp && isInDashboard"
              :display-name="fullName"
              @switch-as-sp="showSsidSwitchModal = true"
            />
            <transition :name="transitionName" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
        <!-- RBO-639 Removed <RetailFooter> since links don't point to anywhere but keeping it here for easy replacement -->
      </div>
    </div>
  </div>
</template>

<script>
import AppLoader from '@/shared/components/AppLoader.vue'
import Hero from '@/shared/components/Hero'
import SideMenu from '@/shared/components/SideMenu/SideMenu.vue'
import useValidators from '@/shared/composables/useValidators.js'
import shapeshifterService from '@/shared/services/shapeshifterService.js'
import { useShapeshifter } from '@postnord/retail-backoffice-components/dist'
import { mapActions, mapGetters, mapState, useStore } from 'vuex'
import { useSingleAccessPoint } from '../../composables/useSingleAccessPoint.js'

export default {
  name: 'MainContent',
  components: {
    Hero,
    SideMenu,
    AppLoader
  },
  setup() {
    const { isUrl } = useValidators()
    const store = useStore()
    const env = process.env.NODE_ENV === 'production' ? 'production' : 'test'
    const { isActingAsSp, switchToMainAccount, getMyServicepoints } =
      useShapeshifter(store, env)
    const {
      toolUrl,
      iframeOpacity,
      manualMenuName,
      shouldLoadFromIFrame,
      initIframe,
      handleSideMenuAsGlobalClick,
      handleMessageEventAsUrlClick,
      validateUrlToNavigate
    } = useSingleAccessPoint()
    return {
      isUrl,
      isActingAsSp,
      switchToMainAccount,
      getMyServicepoints,
      store,
      toolUrl,
      iframeOpacity,
      manualMenuName,
      shouldLoadFromIFrame,
      initIframe,
      handleSideMenuAsGlobalClick,
      handleMessageEventAsUrlClick,
      validateUrlToNavigate
    }
  },
  data() {
    return {
      transitionName: 'slide-fade',
      mobileCollapsed: true,
      desktopCollapsed: false,
      showSsidSwitchModal: false,
      showConfirmSwitchBackModal: false,
      servicepoints: []
    }
  },
  computed: {
    ...mapState('unified', ['permission', 'accessToken']),
    ...mapState('retail', ['servicepoint']),
    ...mapGetters('retail', { isRetailLoggedIn: 'isLoggedIn' }),
    ...mapGetters('unified', {
      isUnifiedLoggedIn: 'isLoggedIn',
      fullName: 'fullName',
      isPostnordEmail: 'isPostnordEmail'
    }),
    isLoggedIn() {
      return this.isRetailLoggedIn || this.isUnifiedLoggedIn
    },
    heroContent() {
      return {
        heading: this.$i18n.t('APP.TITLE'),
        description: this.$i18n.t('APP.DESCRIPTION2')
      }
    },
    isInDashboard() {
      return this.$route.fullPath === '/dashboard'
    },
    currentRouteName() {
      return this.getMenuName(this.$route)
    },
    isUnifiedLoginAuthorized() {
      return (
        this.isUnifiedLoggedIn &&
        this.permission !== null &&
        this.permission >= 0
      )
    },
    showSideMenu() {
      return this.isRetailLoggedIn || this.isUnifiedLoginAuthorized
    },
    environment() {
      return process.env.NODE_ENV
    },
    canShapeshift() {
      return this.isPostnordEmail && this.servicepoints.length > 0
    },
    dynamicPermission() {
      return this.isUnifiedLoginAuthorized && !this.isRetailLoggedIn
        ? this.permission
        : 0
    }
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName =
        toDepth < fromDepth || to.path.includes('dashboard')
          ? 'slide-fade-right'
          : 'slide-fade'

      if (this.shouldLoadFromIFrame) {
        this.initIframe(to.path)
      }
    }
  },
  async mounted() {
    document.addEventListener('click', this.handleSideMenuAsGlobalClick)
    window.addEventListener('message', this.handleMessageEventAsUrlClick)
    if (this.isLoggedIn) {
      this.initIframe(window.location.href)
    }

    if (this.isUnifiedLoggedIn) {
      const response = await this.getMyServicepoints()
      const json = await response?.json()
      this.servicepoints = json?.result
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleSideMenuAsGlobalClick)
    window.removeEventListener('message', this.handleMessageEventAsUrlClick)
  },
  methods: {
    ...mapActions('toaster', ['showToaster']),
    getTranslatedMenuName(name) {
      if (name?.includes('.')) {
        return this.$i18n.t(name)
      } else {
        return name
      }
    },
    getMenuName(menu) {
      if (this.shouldLoadFromIFrame && this.manualMenuName) {
        return this.getTranslatedMenuName(this.manualMenuName)
      } else {
        return this.getTranslatedMenuName(menu.name)
      }
    },
    async handleDidSwitchSp(event) {
      const data = event.data
      this.store.state.loading = true
      this.showSsidSwitchModal = false
      const payload = { ...data }
      payload.ssid = data.sourceId
      payload.store = this.store
      payload.isActingAsSp = this.isActingAsSp
      if (!data.servicepoint) {
        payload.servicepoint = { name: data.sourceId }
      }
      await shapeshifterService.switchToSp(payload)
      this.store.state.loading = false
      window.location.href = '/'
    },
    handleSwitchBackToMainAccount() {
      this.switchToMainAccount()
      this.showConfirmSwitchBackModal = false
      window.location.href = '/'
    },
    onIframeLoaded() {
      setTimeout(() => (this.iframeOpacity = 1), 1000)

      const iframe = this.$refs.toolIframe
      if (!iframe) return

      const css = `
        #app { height: inherit !important; }
        .page-container { margin-left: 0 !important; }
        .side-menu, .top-bar, pn-topbar { display: none !important; }
      `
      const head = iframe.contentDocument.head
      const style = document.createElement('style')
      style.type = 'text/css'
      style.appendChild(document.createTextNode(css))
      head.appendChild(style)
    }
  }
}
</script>

<style lang="scss" scoped>
.main-component {
  width: 100%;

  iframe {
    width: 100%;
    height: calc(100vh - 56px); // deduct 56px for topbar to fix RBO-692
    position: relative;
    border: 0px;
    z-index: 2;
    top: 0;
    left: 0;
    transition: opacity 300ms;
  }

  iframe.shape-shifted {
    height: calc(100vh - 56px - 65px); // deduct topbar and shapeShifter
  }

  :deep(.loader) {
    width: 100%;
  }
}
a {
  text-decoration: none;
  cursor: pointer;
}

.page-container {
  display: block;
}

.page-component {
  width: 100%;

  &--collapsed {
    display: none;
  }
}

::v-deep(pn-modal.switch-back-modal .pn-modal) {
  .modal-footer {
    justify-content: flex-end;

    pn-button {
      margin-left: 1em;
    }
  }
}

@media (min-width: $breakpoint-lg-tablet) {
  .page-container {
    transition: 0.2s ease-in-out;
    margin-left: 19em;
    display: flex;

    &--collapsed {
      margin-left: 3.8em;
    }
  }
}
</style>
