import axios from 'axios'
import {
  decoratedRequestWithApiKey,
  decoratedRequestWithCommonHeaders,
  decoratedRequestWithToken
} from './serviceDecorators.js'

const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_RETAIL_AUTH_URL}`
})

serviceInstance.interceptors.request.use((request) => {
  let newRequest = decoratedRequestWithApiKey(request)
  newRequest = decoratedRequestWithCommonHeaders(newRequest)
  return newRequest
})

export default {
  login: async (ssid, username, password) => {
    const payload = JSON.stringify({ password: password })
    return serviceInstance.post(`/login/${ssid}/${username}`, payload)
  },
  /**
   * Exchanges a retail token (SP Login) into a temporary IAM token used
   * for E-shop and Basic Registration
   */
  exchangeToken: async () => {
    serviceInstance.interceptors.request.use((request) => {
      return decoratedRequestWithToken(request)
    })
    return serviceInstance.post('/token', {})
  }
}
