export const setupWindowReloadOnExpiry = (expiryInSeconds) => {
  const currentTime = Date.now()
  const reloadTime = expiryInSeconds * 1000
  const timeRemaining = reloadTime - currentTime
  if (timeRemaining > 0) {
    setTimeout(function () {
      window.location.reload()
    }, timeRemaining)
  }
}
