import { RETAIL_IAM_TOKEN } from '@/shared/constants/cache'
import axios from 'axios'

const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_RETAIL_FORECAST_BASE_URL}`
})

serviceInstance.interceptors.request.use((request) => {
  request.params = { apikey: process.env.VUE_APP_API_KEY }
  request.headers['Content-type'] = 'application/json; charset=utf-8'
  request.headers.Accept = 'application/json; charset=utf-8'

  const token = localStorage.getItem(RETAIL_IAM_TOKEN)
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  } else {
    request.signal = AbortSignal.abort()
  }
  return request
})

export default {
  getHealth: async () => {
    return serviceInstance.get(`/manage/health`)
  },
  getForecast: async (ssid, fromDate, toDate) => {
    return serviceInstance.get(`/betweendates/${ssid}/${fromDate}/${toDate}`)
  }
}
