import { processError } from '@/shared/utils/errorHandlingUtils.js'
import deliveryAgreementsService from '../services/deliveryAgreementsService.js'

export default {
  namespaced: true,
  state: {
    items: []
  },
  actions: {
    getDeliveryAgreements: async ({ rootState, dispatch }) => {
      rootState.loading = true
      try {
        const response = await deliveryAgreementsService.getAll()
        rootState.deliveryAgreements.items = response?.data
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    createDeliveryAgreement: async (
      { rootState, dispatch, commit },
      payload
    ) => {
      rootState.loading = true
      try {
        const response = await deliveryAgreementsService.create(payload)
        if (response.status === 200) {
          commit('didCreate', response?.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    updateDeliveryAgreement: async (
      { rootState, dispatch, commit },
      payload
    ) => {
      rootState.loading = true
      try {
        const response = await deliveryAgreementsService.update(payload)
        if (response.status === 200) {
          commit('didUpdate', response?.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    deleteDeliveryAgreement: async ({ rootState, dispatch, commit }, id) => {
      rootState.loading = true
      try {
        const response = await deliveryAgreementsService.delete(id)
        if (response.status === 200) {
          commit('didDelete', response?.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    }
  },
  mutations: {
    didCreate() {
      // Do nothing just used for notification
    },
    didUpdate() {
      // Do nothing just used for notification
    },
    didDelete() {
      // Do nothing just used for notification
    }
  }
}
