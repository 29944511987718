import Home from '@/modules/Home/Home.vue'
import { SINGLE_ACCESS_POINT_URL_SUFFIX } from '@/shared/constants/variables'
import { createRouter as _createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes.js'
const Dashboard = () => import('@/modules/Dashboard.vue')
const PageNotFound = () => import('@/modules/PageNotFound.vue')
const Unauthorized = () => import('@/modules/Unauthorized.vue')
const DeliveryListDetails = () =>
  import('@/modules/DeliveryAgreements/DeliveryList/DeliveryListDetails.vue')

const spRoutes = routes.filter((route) => route.component)

export function createRouter() {
  return _createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior: () => ({ top: 0 }),
    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          requiresAuth: false
        }
      },
      {
        name: 'Start',
        path: '/dashboard',
        component: Dashboard
      },
      ...spRoutes,
      {
        path: '/delivery-list/:id',
        component: DeliveryListDetails,
        meta: {
          market: ['SE', 'DK'],
          isExternal: false,
          requiresAuth: true
        }
      },
      {
        path: '/not-found',
        name: 'not-found',
        component: PageNotFound
      },
      {
        path: '/unauthorized',
        name: 'unauthorized',
        component: Unauthorized
      },
      {
        path: '/:pathMatch(.*)*',
        component: PageNotFound,
        beforeEnter: (to, from, next) => {
          if (to.path.includes(SINGLE_ACCESS_POINT_URL_SUFFIX)) {
            next()
          } else {
            next({ name: 'not-found' })
          }
        }
      }
    ]
  })
}
