import { SINGLE_ACCESS_POINT_URL_SUFFIX } from '@/shared/constants/variables.js'
import { traverseCapabilityIfEnabled } from '@/shared/utils/capabilityUtils.js'
import { ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const getNewRouteByMarket = (route, countryCode) => {
  let newRoute = { ...route }
  if (!route.meta?.market?.includes(countryCode)) {
    newRoute = { path: '/not-found' }
  }
  return newRoute
}

const getNewRouteByCapability = (route, capability) => {
  let newRoute = { path: '/not-found' }
  const isEnabled = traverseCapabilityIfEnabled(
    route.meta.capability,
    capability
  )
  if (isEnabled) {
    newRoute = { ...route }
  }
  return newRoute
}

export function useRouteGuard() {
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const isSecuringRoute = ref(true)

  watchEffect(async () => {
    isSecuringRoute.value = true
    try {
      const isRetailLoggedIn = store.getters['retail/isLoggedIn']
      const isUnifiedLoggedIn = store.getters['unified/isLoggedIn']
      const countryCode = store.getters['retail/countryCode']
      let capability = store?.state?.retail?.capability
      const permission = store?.state?.unified?.permission

      if (isRetailLoggedIn) {
        if (route?.meta?.requiresAuth) {
          if (route.meta?.capability !== undefined) {
            if (capability === null) {
              await store.dispatch('retail/getCapability')
              capability = store.state.retail.capability
            }
            router.push(getNewRouteByCapability(route, capability))
          } else {
            router.push(getNewRouteByMarket(route, countryCode))
          }
        }
      } else if (isUnifiedLoggedIn && permission !== null) {
        if (
          document.readyState === 'interactive' &&
          !store?.state?.loading &&
          permission < 0
        ) {
          router?.push({ path: '/unauthorized' })
        } else if (
          !window.location.href.includes(SINGLE_ACCESS_POINT_URL_SUFFIX)
        ) {
          router?.push({ path: '/dashboard' })
        }
      } else if (!isRetailLoggedIn && !isUnifiedLoggedIn) {
        router.push({ path: '/' })
      }
    } catch (error) {
      console.log(error)
    }

    isSecuringRoute.value = false
  })

  return { isSecuringRoute }
}
