import {
  decoratedRequestWithApiKey,
  decoratedRequestWithCommonHeaders,
  decoratedRequestWithToken
} from '@/shared/services/serviceDecorators.js'
import axios from 'axios'

const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_RETAIL_SE_BASE_URL}`
})

serviceInstance.interceptors.request.use((request) => {
  let newRequest = decoratedRequestWithApiKey(request)
  newRequest = decoratedRequestWithCommonHeaders(newRequest)
  newRequest = decoratedRequestWithToken(newRequest)
  return newRequest
})

export default {
  getArrivedItems: async () => {
    return serviceInstance.get('/items/arrived')
  },
  putEvents: async (payload) => {
    const headers = {
      'Device-Id': 'backOffice'
    }
    return serviceInstance.put('/events', payload, { headers })
  }
}
