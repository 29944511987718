import {
  filterByCapability,
  transformedItemsBySpecificCapabilities
} from '@/shared/utils/capabilityUtils'

import {
  decoratedRequestWithCommonHeaders,
  decoratedRequestWithToken
} from '@/shared/services/serviceDecorators.js'
import axios from 'axios'
import { getLocalRetailItems } from './constants/retail'
import { getLocalSpItems } from './constants/servicepoint'
import { addSidemenuMeta, getTokenProps } from './utils'

export const spMenuClient = axios.create({
  baseURL: `${process.env.VUE_APP_WHITELIST_URL}/sidemenu/servicepoint`
})
spMenuClient.interceptors.request.use((request) => {
  let newRequest = decoratedRequestWithCommonHeaders(request)
  newRequest = decoratedRequestWithToken(newRequest)
  return newRequest
})

export const retailMenuClient = axios.create({
  baseURL: `${process.env.VUE_APP_WHITELIST_URL}/sidemenu`
})
retailMenuClient.interceptors.request.use((request) => {
  const newRequest = decoratedRequestWithCommonHeaders(request)
  if (
    newRequest?.headers &&
    newRequest.headers['Authorization'] === undefined
  ) {
    newRequest.headers['Authorization'] = window?.pnTopbar?.access_token
  }
  return newRequest
})

export const filterRetailItems = async (menuItems) => {
  const { azure_usage_location, sourceid } = getTokenProps()
  const market = azure_usage_location
  let filteredItems = menuItems
  if (sourceid) {
    filteredItems = menuItems.filter((item) => {
      if (item.meta?.market) {
        return item.meta.market.some((market) => sourceid.startsWith(market))
      }
      return true
    })
  } else if (market) {
    filteredItems = menuItems.filter((item) => {
      if (item.meta.market) {
        return item.meta.market.includes(market)
      }
      return true
    })
  }
  return filteredItems
}

export const getRetailItems = async (permission, market) => {
  let retailItems = []
  try {
    const res = await retailMenuClient.get()
    let sideMenu = res.data
    if (sideMenu.body) {
      sideMenu = JSON.parse(sideMenu.body)
    }
    retailItems = await filterRetailItems(sideMenu)
  } catch (error) {
    retailItems = getLocalRetailItems()
  }
  retailItems = retailItems
    .filter((item) => item.meta?.permission <= permission)
    .filter(
      (item) => !item.meta?.markets || item.meta?.markets.includes(market)
    )
  return addSidemenuMeta(retailItems)
}

export const filterSpItems = async (menuItems, capability) => {
  let filteredItems = filterByCapability(menuItems, capability)
  filteredItems = transformedItemsBySpecificCapabilities(
    filteredItems,
    capability
  )
  return filteredItems
}

export const getSpItems = async (capability) => {
  let spItems = []
  try {
    const res = await spMenuClient.get()
    let sideMenu = res.data
    if (sideMenu.body) {
      sideMenu = JSON.parse(sideMenu.body)
    }
    spItems = await filterSpItems(sideMenu, capability)
  } catch (error) {
    spItems = getLocalSpItems()
  }
  return addSidemenuMeta(spItems)
}
