import { processError } from '@/shared/utils/errorHandlingUtils.js'
import forecastBackofficeService from '../services/forecastBackofficeService.js'
import forecastService from '../services/forecastService.js'

export default {
  namespaced: true,
  state: {
    loading: false,
    forecasts: []
  },
  actions: {
    getBackofficeForecast: async (
      { rootState, dispatch },
      { fromDate, toDate }
    ) => {
      rootState.forecast.loading = true
      try {
        const response = await forecastBackofficeService.getForecast(
          fromDate,
          toDate
        )
        rootState.forecast.forecasts = response.data ? response.data : []
      } catch (error) {
        console.log(error)
        const overridenError = 'Unable to fetch any forecast data'
        processError(overridenError, dispatch)
      }
      rootState.forecast.loading = false
    },
    getForecast: async (
      { rootState, dispatch },
      { ssid, fromDate, toDate }
    ) => {
      rootState.forecast.loading = true
      try {
        const response = await forecastService.getForecast(
          ssid,
          fromDate,
          toDate
        )
        rootState.forecast.forecasts = response.data ? response.data : []
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.forecast.loading = false
    }
  }
}
