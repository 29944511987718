import { alert_exclamation_circle, check } from '@/shared/constants/icons.js'
import { PNTOPBAR_IAM_TOKEN } from '@postnord/retail-backoffice-components/dist'

const getSwitchToast = (isSuccess, isSp, displayName) => {
  const successMessage = isSp ? 'Switched view to' : 'Switched back to'
  const failedMessage = isSp
    ? 'Failed to switch view to'
    : 'Failed to switch back to'
  return {
    showClose: false,
    icon: isSuccess ? check : alert_exclamation_circle,
    appearance: isSuccess ? 'success' : 'warning',
    message: isSuccess
      ? `${successMessage} ${displayName}`
      : `${failedMessage} ${displayName}`,
    show: true
  }
}

export default {
  switchToSp: async (payload) => {
    const { servicepoint: sp, isActingAsSp, ssid, store } = payload
    const showToaster = (toast) => store?.dispatch('toaster/showToaster', toast)
    const TMP_ACTIVE_SP = 'TMP_ACTIVE_SP'
    const localActiveSp = {
      set: (sp) => localStorage.setItem(TMP_ACTIVE_SP, JSON.stringify(sp)),
      clear: () => localStorage.removeItem(TMP_ACTIVE_SP)
    }
    if (ssid) {
      if (isActingAsSp) {
        store?.dispatch('retail/logout')
        localActiveSp.clear()
      }
      try {
        const token =
          JSON.parse(localStorage.getItem(PNTOPBAR_IAM_TOKEN))?.value || null
        let url = `${process.env.VUE_APP_RETAIL_AUTH_URL}/iamlogin/${ssid}`
        const apiKey = process.env.VUE_APP_API_KEY
        url += `?apikey=${apiKey}`
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token
          }
        })
        const json = await response.json()
        const jwtToken = json?.jwttoken?.token || null
        if (jwtToken !== null) {
          localActiveSp.set(sp)
          showToaster(getSwitchToast(true, true, sp?.name))
          store.state.retail.authorization = jwtToken
          store.state.retail.servicepoint = payload.servicepoint
          store.commit('retail/didLogin')
        } else {
          throw new Error('Unable to parse for jwt token')
        }
      } catch {
        showToaster(getSwitchToast(false, true, sp?.name))
      }
    } else {
      showToaster(getSwitchToast(false, true, sp?.name))
    }
  }
}
