import axios from 'axios'
import {
  decoratedRequestWithApiKey,
  decoratedRequestWithCommonHeaders,
  decoratedRequestWithToken
} from '@/shared/services/serviceDecorators.js'

const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_RETAIL_SE_BASE_URL}/cashregister`
})

serviceInstance.interceptors.request.use((request) => {
  let newRequest = decoratedRequestWithApiKey(request)
  newRequest = decoratedRequestWithCommonHeaders(newRequest)
  newRequest = decoratedRequestWithToken(newRequest)
  return newRequest
})

export default {
  getStatus: async () => {
    return serviceInstance.get('/status')
  },
  setBalance: async (payload) => {
    return serviceInstance.post('/balance', payload)
  }
}
