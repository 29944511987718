import axios from 'axios'

export default {
  getPermission: async (token) => {
    return axios.get(`${process.env.VUE_APP_RETAIL_PORTAL_API}/permissions`, {
      headers: {
        Authorization: token,
        'x-bap-key': process.env.VUE_APP_BAP_KEY
      }
    })
  }
}
