import { processError } from '@/shared/utils/errorHandlingUtils.js'
import fallbackService from '../services/fallbackService.js'

const defaultState = {
  items: []
}

export default {
  namespaced: true,
  state: defaultState,
  actions: {
    getArrivedItems: async ({ rootState, dispatch }) => {
      rootState.loading = true
      try {
        const result = await fallbackService.getArrivedItems()
        const itemList = result?.data?.itemList?.items
        if (itemList) {
          rootState.fallback.items = itemList
        } else {
          processError('Unable to retreive items.', dispatch)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    putEvents: async ({ rootState, dispatch, commit }, payload) => {
      rootState.loading = true
      try {
        const response = await fallbackService.putEvents(payload)
        if (response.status === 200) {
          const printId =
            response?.data?.eventresponse?.customersessions[0]?.printid
          commit('didPutEvents', printId)
        } else {
          processError('Unable to print local notification.', dispatch)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    }
  },
  mutations: {
    didPutEvents() {
      // Do nothing just used for notification
    }
  }
}
