<template>
  <div class="top-bar">
    <a href="/">
      <BrandLogo color="#FFF" class="top-bar-logo" />
    </a>
    <div class="top-bar-portals">{{ title ? title : 'Retail Backoffice' }}</div>
    <div v-if="!isProduction" class="stage-tag-container">
      <div class="stage-tag">Test</div>
    </div>
    <div class="top-bar-login">
      <pn-button
        v-show="showHelp"
        appearance="dark"
        :icon="alert_question_circle"
        variant="borderless"
        @click="clickHelp"
        >{{ $t('APP.HELP') }}</pn-button
      >
      <span v-show="showHelp">|</span>
      <div v-if="isLoggedIn" class="logged-in">
        <TopbarDropdown class="user-data" :label="userInfo.username">
          <div v-if="userInfo" class="user-info">
            <h4>Logged in as</h4>

            <span class="med-label">{{ userInfo.username }}</span>
            <span class="small-label">Role: {{ userInfo.role }}</span>
            <span class="small-label">ID: {{ userInfo.id }}</span>
          </div>
          <HorizontalDivider />
          <pn-button
            appearance="light"
            variant="borderless"
            class="logout-button"
            @click="logout"
          >
            {{ $t('APP.LOGOUT') }}
          </pn-button>
        </TopbarDropdown>
        <div v-if="countryCode" class="user-data country">
          <span
            class="flag-icon"
            :class="`flag-icon-${countryCode.toLowerCase()}`"
          ></span>
          {{ country }}
        </div>
      </div>
      <div v-else>
        <pn-button
          v-show="showLogin && !isProduction"
          left-icon
          appearance="dark"
          variant="borderless"
          :icon="user"
          @click="login"
          >{{ $t('APP.LOGIN') }}</pn-button
        >
      </div>
      <TopbarDropdown :label="getLanguageName(selectedLang)" :icon="globe">
        <pn-button
          v-for="(language, index) in languages"
          :key="index"
          appearance="light"
          variant="borderless"
          @click="changeLanguage(language)"
        >
          {{ getLanguageName(language) }}
        </pn-button>
      </TopbarDropdown>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { getCountryName, getLanguageName } from '@/i18n/i18n.js'
import { alert_question_circle, globe, user } from '@/shared/constants/icons.js'

export default {
  name: 'RetailTopbar',
  props: {
    isLoggedIn: Boolean,
    title: {
      type: String,
      default() {
        return 'Postnord Retail'
      }
    },
    userInfo: {
      type: Object,
      default() {
        return {
          username: '',
          role: null,
          id: null
        }
      }
    },
    showLogin: {
      type: Boolean,
      default() {
        return false
      }
    },
    showHelp: {
      type: Boolean,
      default() {
        return true
      }
    },
    countryCode: {
      type: String,
      default() {
        return null
      }
    }
  },
  emits: ['logout', 'login', 'didClickHelp'],
  data() {
    return {
      selectedLang: 'en',
      alert_question_circle,
      user,
      globe
    }
  },
  computed: {
    ...mapState(['lang']),
    ...mapGetters(['isProduction']),
    country() {
      return getCountryName(this.countryCode) || null
    },
    languages() {
      return this.$i18n.availableLocales || ['en', 'sv']
    }
  },
  watch: {
    lang: {
      immediate: true,
      handler(val) {
        this.selectedLang = val
        this.$i18n.locale = val
      }
    }
  },
  methods: {
    ...mapActions(['setLocalizationState']),
    logout() {
      this.$emit('logout')
    },
    login() {
      this.$emit('login')
    },
    clickHelp() {
      this.$emit('didClickHelp')
    },
    changeLanguage(language) {
      this.selectedLang = language
      this.setLocalizationState(language)
      // Force reload to reload the styling
      window.location.reload()
    },
    getLanguageName
  }
}
</script>
<style scoped lang="scss">
.top-bar {
  display: flex;
  align-items: center;
  background: $blue700;
  height: 56px;
  width: 100%;
  color: $white;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
    0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
}
.top-bar-logo {
  width: 133px;
  height: auto;
  margin: 7px 1em 0 1em;
  margin-top: 7px;
}

.top-bar-login {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1em;
  .user-data {
    color: $white;
    text-decoration: none;
    margin-right: 1.5em;

    .flag-icon {
      margin-right: 8px;
    }
  }
}

.top-bar-portals {
  padding: 0 1em;
  margin: 0 1em;
  border-left: 1px solid $white;
}

.logged-in {
  display: flex;
  align-items: center;
}

.user-info {
  display: grid;
  color: $black;
  min-width: 180px;
  margin-bottom: 1em;

  h4 {
    margin-top: unset;
  }
  .flex-group {
    justify-content: space-between;
  }
}
.logout-button {
  margin-top: 1em;
}

.stage-tag {
  border-radius: 4px;
  height: 32px;
  width: 40px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  background-color: #1e1e1e;
}

.small-label {
  color: $gray700;
  font-size: smaller;
}
.med-label {
  line-height: 1.5em;
}

@media only screen and (max-width: 600px) {
  .stage-tag-container {
    display: none;
  }

  .user-data.country {
    display: none;
  }
}

@media only screen and (max-width: 415px) {
  .top-bar-portals {
    display: none;
  }
}
</style>
