import { handleError } from '@/store/toaster'

export const getFirstCompositeFault = (response) => {
  if (response?.compositeFault?.faults?.length) {
    return response.compositeFault.faults[0].explanationText
  }
  return null
}

export const processError = (error, dispatch) => {
  let message = error
  const fault = getFirstCompositeFault(error?.response?.data)
  if (fault) {
    message = fault
  } else if (error?.message?.includes('canceled')) {
    return
  }
  handleError(dispatch, message)
}
