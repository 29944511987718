const PERMISSION_TYPES = {
  guest: 0,
  read: 1,
  write: 2,
  admin: 3
}
const origin = window.location.origin
const env = process.env.NODE_ENV

export const getTestTool = () => {
  return env !== 'production'
    ? []
    : [
        {
          name: 'Retail Label Test',
          url: `${origin}/test-tool/`,
          meta: {
            id: 'testool',
            permission: PERMISSION_TYPES.read,
            icon: 'barcode',
            illustration: 'notifications'
          }
        }
      ]
}

export const getLocalRetailItems = () => {
  return [
    {
      name: 'Start',
      url: origin,
      meta: {
        id: 'backoffice',
        permission: PERMISSION_TYPES.guest,
        icon: 'home',
        illustration: 'postnord_customer_service'
      }
    },
    {
      name: 'Items Backoffice',
      url: `${origin}/items/`,
      meta: {
        id: 'itemsbackoffice',
        permission: PERMISSION_TYPES.read,
        icon: 'postcard',
        illustration: 'customer_service'
      }
    },
    {
      name: 'Search Delivery Agreements',
      url: `${origin}/delivery-agreements/`,
      meta: {
        id: 'deliveryagreements',
        permission: PERMISSION_TYPES.read,
        icon: 'search',
        illustration: 'person_computer'
      }
    },
    {
      name: 'Retail User Administration',
      url: `${origin}/user-admin/`,
      meta: {
        id: 'useradmin',
        permission: PERMISSION_TYPES.read,
        icon: 'users',
        illustration: 'company_settings'
      }
    },
    ...getTestTool(),
    {
      name: 'Retail Emergency Fallback - Superuser',
      url: `${origin}/fallback-superuser/`,
      meta: {
        id: 'fallbacksuperuser',
        permission: PERMISSION_TYPES.read,
        icon: 'alert_exclamation_circle',
        illustration: 'newsletter_map_calendar'
      }
    },
    {
      name: 'Capabilities Backoffice',
      url: `${origin}/capabilities/`,
      meta: {
        id: 'capabilities',
        permission: PERMISSION_TYPES.admin,
        icon: 'settings',
        illustration: 'dashboard'
      }
    },
    {
      name: 'Whitelisting Backoffice',
      url: `${origin}/whitelists/`,
      meta: {
        id: 'whitelists',
        permission: PERMISSION_TYPES.admin,
        icon: 'list',
        illustration: 'claims'
      }
    },
    {
      name: 'Retail Trumpet',
      url: `${origin}/trumpet/`,
      meta: {
        id: 'trumpet',
        permission: PERMISSION_TYPES.admin,
        icon: 'bullhorn',
        illustration: 'mobile_notification'
      }
    },
    {
      name: 'Retail Messages',
      url: `${origin}/messages/`,
      meta: {
        id: 'messageweb',
        permission: PERMISSION_TYPES.admin,
        icon: 'envelope',
        illustration: 'mailbox_plant_letters',
        markets: env === 'production' ? ['SE'] : ['SE', 'DK']
      },
      children: [
        {
          name: 'Inbox',
          path: '/inbox',
          url: `${origin}/messages/inbox`,
          meta: {
            id: 'messageweb-inbox',
            icon: ''
          }
        },
        {
          name: 'Sent',
          path: '/sent',
          url: `${origin}/messages/sent`,
          meta: {
            id: 'messageweb-sent',
            icon: ''
          }
        },
        {
          name: 'Archived',
          path: '/archived',
          url: `${origin}/messages/archived`,
          meta: {
            id: 'messageweb-archived',
            icon: ''
          }
        }
      ]
    }
  ]
}
