<template>
  <div class="hero">
    <div class="hero-content">
      <div class="hero-left">
        <h1>{{ content.heading }}</h1>
        <span>{{ content.description }}</span>
      </div>
      <img src="@/assets/svg/computer-letter-flowers.svg" alt="truck" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    content: {
      type: Object,
      default() {
        return {
          heading: '',
          description: ''
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hero {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 250px;
  color: $black;
  padding: 0 10px;
  background-color: $blue25;
  background-size: cover;
  overflow: hidden;

  h1 {
    margin: 5px 0;
  }
  img {
    max-width: 600px;
    width: 45%;
    position: absolute;
    bottom: -2.5em;
    right: 0;
  }
}
.hero-left {
  text-align: left;
  span {
    color: $gray700;
  }
}
.hero-content {
  display: flex;
  justify-content: space-between;
  padding: 0 4em;
  align-items: center;
  height: inherit;
  position: relative;
}
.circle {
  background: $blue200;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  transform: translate(100px, 0px);
}
@media (max-width: 540px) {
  .hero {
    img {
      width: 300px;
      position: absolute;
      right: -50px;
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .hero {
    img {
      display: none;
    }
  }

  .hero-content {
    padding: 2em;
  }
}
</style>
