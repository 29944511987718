const ArrivedItems = () => import('@/modules/Fallback/ArrivedItems.vue')
const Forecast = () => import('@/modules/Forecast/Forecast.vue')
const DailyBalance = () => import('@/modules/DailyBalance/DailyBalance.vue')
const EButiken = () => import('@/modules/EButiken/EButiken.vue')
const BaseRegister = () => import('@/modules/BaseRegister/BaseRegister.vue')
const CustomerManagement = () =>
  import(
    '@/modules/DeliveryAgreements/CustomerManagement/CustomerManagement.vue'
  )
const DeliveryLists = () =>
  import('@/modules/DeliveryAgreements/DeliveryList/DeliveryLists.vue')

const fallback = {
  name: 'FALLBACK.TITLE',
  description: 'FALLBACK.DESCRIPTION',
  path: '/fallback/items',
  component: ArrivedItems,
  meta: {
    market: ['SE', 'DK', 'FI', 'NO'],
    isExternal: false,
    requiresAuth: true
  }
}

const forecast = {
  name: 'FORECAST.TITLE',
  description: 'FORECAST.DESCRIPTION',
  path: '/forecast',
  component: Forecast,
  meta: {
    market: ['DK'],
    isExternal: false,
    requiresAuth: true,
    capability: 'servicepointBackoffice.forecastEnable'
  }
}

const dailyBalance = {
  name: 'DAILY_BALANCE.TITLE',
  description: 'DAILY_BALANCE.DESCRIPTION',
  path: '/daily-balance',
  component: DailyBalance,
  meta: {
    market: ['SE'],
    isExternal: false,
    requiresAuth: true,
    capability: 'cashier.acceptCashPayments'
  }
}

const customerManagement = {
  name: 'CUSTOMER_MANAGEMENT.TITLE',
  description: 'CUSTOMER_MANAGEMENT.DESCRIPTION',
  path: '/manage-customer',
  component: CustomerManagement,
  meta: {
    market: ['SE'],
    isExternal: false,
    requiresAuth: true,
    capability: 'deliveryAgreement.enable'
  }
}

const deliveryList = {
  name: 'DELIVERY_LIST.TITLE',
  description: 'DELIVERY_LIST.DESCRIPTION',
  path: '/delivery-list',
  component: DeliveryLists,
  meta: {
    market: ['SE'],
    isExternal: false,
    requiresAuth: true,
    capability: 'deliveryAgreement.enable'
  }
}

const ebutiken = {
  name: 'EBUTIKEN.TITLE',
  description: 'EBUTIKEN.DESCRIPTION',
  path: '/e-butiken',
  component: EButiken,
  meta: {
    market: ['SE'],
    isExternal: false,
    requiresAuth: true,
    capability: 'servicepointBackoffice.eshopEnable'
  }
}

const baseRegister = {
  name: 'BASEREGISTER.TITLE',
  description: 'BASEREGISTER.DESCRIPTION',
  path: '/baseregistration',
  component: BaseRegister,
  meta: {
    market: ['SE'],
    isExternal: false,
    requiresAuth: true,
    capability: 'servicepointBackoffice.baseRegistrationEnable'
  }
}

export const routes = [
  fallback,
  forecast,
  dailyBalance,
  customerManagement,
  deliveryList,
  ebutiken,
  baseRegister
]
