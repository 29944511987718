import { processError } from '@/shared/utils/errorHandlingUtils.js'
import cashRegisterService from '../services/cashRegisterService.js'

export default {
  namespaced: true,
  state: {
    cashRegisterState: null,
    closingError: null
  },
  actions: {
    getStatus: async ({ rootState, dispatch }) => {
      rootState.loading = true
      try {
        const response = await cashRegisterService.getStatus()
        rootState.cashRegister.cashRegisterState = response?.data
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    setBalance: async ({ rootState, dispatch, commit }, payload) => {
      rootState.loading = true
      try {
        const response = await cashRegisterService.setBalance(payload)
        if (response.status === 200) {
          commit('didSetBalance', response?.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    }
  },
  getters: {
    cashRegisterStatus: (state) => {
      return state.cashRegisterState?.status
    },
    requiresBalancing: (state) => {
      return state.cashRegisterState?.requiresBalancing
    },
    accountDay: (state) => {
      return state.cashRegisterState?.accountDay
    },
    balanceValue: (state) => {
      return state.cashRegisterState?.balance?.value
    },
    isCashRegisterOpen: (state) => {
      return state.cashRegisterState?.status === 'open'
    }
  },
  mutations: {
    didSetBalance() {
      // Do nothing just used for notification
    }
  }
}
