import { urls } from '@/shared/constants/validReturnUrls.js'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

export function useRedirect() {
  const _returnUrl = ref('')
  const route = useRoute()

  onMounted(() => {
    const { returnUrl } = route.query
    _returnUrl.value = returnUrl
  })

  const isForRedirection = computed(() => {
    return _returnUrl.value && urls.includes(_returnUrl.value)
  })

  return { _returnUrl, isForRedirection }
}
