import { processError } from '@/shared/utils/errorHandlingUtils.js'
import deliverylistsService from '../services/deliverylistsService.js'

export default {
  namespaced: true,
  state: {
    items: [],
    current: null
  },
  actions: {
    search: async ({ rootState, dispatch }, payload) => {
      rootState.loading = true
      try {
        const response = await deliverylistsService.search(payload)
        const results = response?.data
        rootState.deliverylists.items = results
        // Select the current if items search results is just one
        if (results && results.length === 1) {
          rootState.deliverylists.current = results[0]
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    selectDeliveryList: ({ rootState }, deliveryList) => {
      rootState.deliverylists.current = deliveryList
    },
    printDeliveryList: async ({ rootState, dispatch, commit }, id) => {
      rootState.loading = true
      try {
        const response = await deliverylistsService.print(id)
        if (response.status === 200) {
          commit('didPrintList', response?.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    printMultipleDeliveryList: async ({ rootState, dispatch, commit }, ids) => {
      rootState.loading = true
      try {
        const serviceCalls = []
        const responses = []

        ids.forEach((id) => {
          serviceCalls.push(deliverylistsService.print(id))
        })
        const results = await Promise.all(serviceCalls)

        responses.push(...results)

        if (responses.every((response) => response.status === 200)) {
          const allBase64Data = responses.map((response) => response.data)
          commit('didPrintList', allBase64Data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    addItem: async ({ rootState, dispatch, commit }, { id, itemId }) => {
      rootState.loading = true
      try {
        const response = await deliverylistsService.patchItem(id, itemId)
        if (response.status === 200) {
          commit('didAddItem', response?.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    acknowledgeDeliveryList: async ({ rootState, dispatch, commit }, id) => {
      rootState.loading = true
      try {
        const response = await deliverylistsService.patchAcknowledge(id)
        if (response.status === 200) {
          commit('didAcknowledgeList', response?.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    deleteItem: async ({ rootState, dispatch, commit }, id) => {
      rootState.loading = true
      try {
        const response = await deliverylistsService.deleteItem(id)
        if (response.status === 200) {
          commit('didDeleteItem', response?.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    },
    sendEmail: async ({ rootState, dispatch, commit }, { id, email }) => {
      rootState.loading = true
      try {
        const response = await deliverylistsService.sendEmail(id, email)
        if (response.status === 200) {
          commit('didSendEmail', response?.data)
        }
      } catch (error) {
        processError(error, dispatch)
      }
      rootState.loading = false
    }
  },
  mutations: {
    didPrintList() {
      // Do nothing just used for notification
    },
    didAcknowledgeList() {
      // Do nothing just used for notification
    },
    didAddItem() {
      // Do nothing just used for notification
    },
    didDeleteItem() {
      // Do nothing just used for notification
    },
    didSendEmail() {}
  }
}
