import {
  RETAIL_JWT_TOKEN,
  RETAIL_LANGUAGE
} from '@postnord/retail-backoffice-components/dist'

export const decoratedRequestWithCommonHeaders = (request) => {
  const decorated = { ...request }
  decorated.headers['Content-type'] = 'application/json; charset=utf-8'
  decorated.headers.Accept = 'application/json; charset=utf-8'
  return decorated
}

export const decoratedRequestWithToken = (request) => {
  const decorated = { ...request }
  const token = localStorage.getItem(RETAIL_JWT_TOKEN)
  if (token) {
    decorated.headers.Authorization = token
  } else {
    decorated.signal = AbortSignal.abort()
  }
  return decorated
}

export const decoratedRequestWithApiKey = (request) => {
  const decorated = { ...request }
  const language = localStorage.getItem(RETAIL_LANGUAGE) || 'en'
  decorated.params = {
    language: language,
    apikey: process.env.VUE_APP_API_KEY
  }
  return decorated
}
