import {
  decoratedRequestWithCommonHeaders,
  decoratedRequestWithToken
} from '@/shared/services/serviceDecorators.js'
import axios from 'axios'

const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_RETAIL_PORTAL_FORECAST_API}`
})

serviceInstance.interceptors.request.use((request) => {
  let newRequest = decoratedRequestWithCommonHeaders(request)
  newRequest = decoratedRequestWithToken(newRequest)
  return newRequest
})

export default {
  getForecast: async (fromDate, toDate) => {
    return serviceInstance.get(`/forecast/${fromDate}/${toDate}`)
  }
}
