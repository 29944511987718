import {
  decoratedRequestWithApiKey,
  decoratedRequestWithCommonHeaders,
  decoratedRequestWithToken
} from '@/shared/services/serviceDecorators.js'
import axios from 'axios'

export const serviceInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'https://82mrp133ok.execute-api.eu-west-1.amazonaws.com/test/deliverylists'
      : `${process.env.VUE_APP_RETAIL_SE_BASE_URL}/deliverylists`
})

serviceInstance.interceptors.request.use((request) => {
  let newRequest = decoratedRequestWithApiKey(request)
  newRequest = decoratedRequestWithCommonHeaders(newRequest)
  newRequest = decoratedRequestWithToken(newRequest)
  return newRequest
})

export default {
  search: async (payload) => {
    return serviceInstance.post('/search', payload)
  },
  print: async (id) => {
    return serviceInstance.get(`/${id}/print?email=Y`)
  },
  patchItem: async (id, itemId) => {
    return serviceInstance.patch(`/${id}/items/${itemId}`)
  },
  patchAcknowledge: async (id) => {
    return serviceInstance.patch(`/${id}/acknowledged`)
  },
  deleteItem: async (id) => {
    return serviceInstance.delete(`/items/${id}`)
  },
  sendEmail: async (id, email) => {
    return serviceInstance.post(`/${id}/sendEmail`, { emailAddress: email })
  }
}
