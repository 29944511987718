import { createI18n } from 'vue-i18n'

import ArticleManagement from './translations/ArticleManagement/translated.json'
import BaseRegister from './translations/BaseRegister/translated.json'
import CashDelivery from './translations/CashDelivery/translated.json'
import DailyBalance from './translations/DailyBalance/translated.json'
import CM from './translations/DeliveryAgreements/CustomerManagement/translated.json'
import DeliveryList from './translations/DeliveryAgreements/DeliveryList/translated.json'
import EButiken from './translations/EButiken/translated.json'
import Fallback from './translations/Fallback/translated.json'
import Forecast from './translations/Forecast/translated.json'
import PostPortal from './translations/PostPortal/translated.json'
import App from './translations/translated.json'

const messages = { en: {}, sv: {}, da: {}, fi: {}, no: {} }
Object.keys(messages).forEach((lang) => {
  messages[lang] = {
    ...App[lang],
    ...DailyBalance[lang],
    ...Forecast[lang],
    ...Fallback[lang],
    ...EButiken[lang],
    ...CashDelivery[lang],
    ...CM[lang],
    ...DeliveryList[lang],
    ...BaseRegister[lang],
    ...ArticleManagement[lang],
    ...PostPortal[lang]
  }
})

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'sv', // set fallback locale
  messages // set locale messages
})

export default i18n

export const getLanguageName = (code) => {
  const mapper = {
    en: 'English',
    sv: 'Svenska',
    da: 'Dansk',
    no: 'Norwegian',
    fi: 'Finnish'
  }
  return mapper[code.toLowerCase()] || ''
}

export const getCountryName = (code) => {
  const mapper = {
    se: 'Sweden',
    dk: 'Denmark',
    no: 'Norway',
    fi: 'Finland'
  }
  return mapper[code.toLowerCase()] || ''
}
