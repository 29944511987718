import InvalidUrlError from '@/shared/classes/InvalidUrlError'
import emailValidator from 'validator/lib/isEmail'
import urlValidator from 'validator/lib/isURL'

export default function useValidators() {
  const isEmail = (email) => {
    return email !== null && emailValidator(email)
  }

  const isUrl = (url) => {
    const isValid = urlValidator(url)
    if (!isValid) {
      throw new InvalidUrlError('The provided value is not a valid URL')
    }
    return isValid
  }

  return { isEmail, isUrl }
}
