export function getNordicCurrencyFormat(value) {
  if (isNaN(value) || value === 0) {
    return value
  }

  const actual = value.toString()
  const whole = actual.substring(0, actual.length - 2)
  const decimal = actual.substr(actual.length - 2, 2)
  const newValue = `${whole}.${decimal}`
  const converted = parseFloat(newValue)
  const val = (converted / 1).toFixed(2).replace('.', ',')

  if (value > 999) {
    return val.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1 ')
  } else {
    return newValue.replace('.', ',')
  }
}

export const validatePhoneNumber = (input) => {
  const validCountryCode = /^([+]\d{1,3})$/
  const validAreaCode = /^(\d{2,3})$/
  const validNumber =
    /^(\d{6,8}|\d{3,4}[-\s]?\d{3,4}|\d{3}[-\s]?\d{2}|\d{2,3}[-\s]?\d{2}[-\s]?\d{2})$/
  if (input.length <= 18) {
    const separator = input.split(' ')
    const countryCode = separator[0]
    const secondPartOfNumber = separator[1]
    let number = ''
    if (secondPartOfNumber?.length > 3) {
      const fullNumber = secondPartOfNumber
      return validCountryCode.test(countryCode) && validNumber.test(fullNumber)
    } else {
      const areaCode = secondPartOfNumber
      number = separator.slice(2).join(' ')
      return (
        validCountryCode.test(countryCode) &&
        validAreaCode.test(areaCode) &&
        validNumber.test(number)
      )
    }
  }
  return false
}

export const isTokenExpired = (expiryTime = 1) =>
  expiryTime * 1000 - Date.now() < 180000
